import React from 'react'
import Page from '../components/Page/Page'
//import Picker from '../components/Picker/Picker'
import Bogen from '../components/Bogen/Bogen'
import PriceTag from '../components/PriceTag/PriceTag'

import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './boegen.module.css'

import { Location } from '@reach/router';


const BoegenPage = ({ data }) => {
  const bowsPageData = data.page;

  const bowsSortedByCategory = data.bows.nodes.reduce((acc, it) => {
        var i = -1;
        it.category === 'violin' ? i = 0 : i = 1;
        acc[i].push(<Bogen
          name={it.name}
          price={it.price}
          weight={it.weight}
          description={it.description.json}
          images={it.images}
          key={`${it.name}${it.weight}${it.price}`}
        />);
        return acc;
      }, [[],[]]);

  const sortByPrice = (a, b) => {
    if (a.props.price < 0) return 1;
    if (b.props.price < 0) return -1;
    return a.props.price - b.props.price;
  };

  bowsSortedByCategory.map(e => e.sort(sortByPrice));

  // Content for Picker - currently not in use
  //const content = [
  //  ...bowsSortedByCategory,
  //  documentToReactComponents(bowsPageData.meisterCelloBogenText.json),
  //  documentToReactComponents(bowsPageData.meisterBassBogenText.json)
  //];

  return (
    <Location>
      {({ location }) => {
        const onENRoute = (location.pathname.indexOf('/en/') !== -1);
        const violinNameLocal = onENRoute ? 'Violin' : 'Violine';

        return (
          <Page
            title={bowsPageData.pageTitle}
            renderCallback={() => {
              return(
                <div>
                  <div>
                    {documentToReactComponents(bowsPageData.schuelerBoegenText.json)}
                    <table styleName={'studentBowsTable'}>
                      <tbody>
                        <tr>
                          <td/>
                          <td styleName={'bold center'}>{violinNameLocal}</td>
                          <td styleName={'bold center'}>Viola</td>
                          <td styleName={'bold center'}>Cello</td>
                          <td styleName={'bold center'}>Bass</td>
                        </tr>
                        <tr>
                          <td styleName={'left bold'}>{bowsPageData.einfacheSchuelerBogenText}</td>
                          <td styleName={'center'}><PriceTag price={bowsPageData.einfacheSchuelerViolinBoegenPreis} /></td>
                          <td styleName={'center'}><PriceTag price={bowsPageData.einfacheSchuelerViolaBoegenPreis} /></td>
                          <td styleName={'center'}><PriceTag price={bowsPageData.einfacheSchuelerCelloBoegenPreis} /></td>
                          <td styleName={'center'}><PriceTag price={bowsPageData.einfacheSchuelerBassBoegenPreis} /></td>
                        </tr>
                        <tr>
                          <td styleName={'left bold'}>{bowsPageData.bessereSchuelerBogenText}</td>
                          <td styleName={'center'}><PriceTag price={bowsPageData.bessereSchuelerViolinBoegenPreis} /></td>
                          <td styleName={'center'}><PriceTag price={bowsPageData.bessereSchuelerViolaBoegenPreis} /></td>
                          <td styleName={'center'}><PriceTag price={bowsPageData.bessereSchuelerCelloBoegenPreis} /></td>
                          <td styleName={'center'}><PriceTag price={bowsPageData.bessereSchuelerBassBoegenPreis} /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    {documentToReactComponents(bowsPageData.meisterBoegenText.json)}
                  </div>
                  {/*<Picker
                    content={content}
                    customContent={true}
                  />*/}
                </div>
            )}}
          />
      )}}
  </Location>
  )
}

export default BoegenPage

export const bowsPageFragment = graphql `
  fragment BowsPageQueryFragment on ContentfulBowsPage {
    pageTitle
    schuelerBoegenText {
      json
    }
    meisterBoegenText {
      json
    }
    meisterBassBogenText {
      json
    }
    meisterCelloBogenText {
      json
    }
    einfacheSchuelerBogenText
    bessereSchuelerBogenText
    einfacheSchuelerViolinBoegenPreis
    bessereSchuelerViolinBoegenPreis
    einfacheSchuelerViolaBoegenPreis
    bessereSchuelerViolaBoegenPreis
    einfacheSchuelerCelloBoegenPreis
    bessereSchuelerCelloBoegenPreis
    einfacheSchuelerBassBoegenPreis
    bessereSchuelerBassBoegenPreis
  }
`

export const bowsFragment = graphql `
  fragment BowsQueryFragment on ContentfulBow {
    price
    weight
    name
    images {
      file {
        url
      }
      description
    }
    description {
      json
    }
    category
  }
`

export const bowsPageQuery = graphql `
  query bowsPageDEQuery {
    page: contentfulBowsPage(node_locale: {eq: "de"}) {
        ...BowsPageQueryFragment
    }
    bows: allContentfulBow(filter: {node_locale: {eq: "de"}}) {
      nodes {
        ...BowsQueryFragment
      }
    }
  }
`
