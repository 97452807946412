import React from 'react'
import './bogen.module.css'

import { Location } from '@reach/router';
import { StaticQuery, graphql } from 'gatsby';
import PriceTag from '../PriceTag/PriceTag';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


const Bogen = (props) => (
  <Location>
    {({ location }) => {
      const onENRoute = (location.pathname.indexOf('/en/') !== -1);
      const i = onENRoute ? 0 : 1;

      return (
        <StaticQuery
          query={bogenLabelsQuery}
          render={data => {
            const labels = data.allContentfulProductLabels.nodes[i];
            return (
            <div styleName={'bogenContainer'}>
              <div styleName={'name'}>
                {props.name}
              </div>
              <div styleName={'detailsContainer'}>
                <div styleName={'imageContainer'}>
                  {props.images.map(e=> <img styleName={'image'} src={`https://${e.file.url}`} alt={e.description} key={e.file.url} />)}
                </div>
                <div styleName={'bogenInfoContainer'}>
                  <div styleName={'description'}>
                    {documentToReactComponents(props.description)}
                  </div>
                  <div styleName={'priceWeightContainer'}>
                    <div styleName={'price'}>
                      <span styleName={'keyword'}>{labels.weightLabel} </span>{props.weight}g
                    </div>
                    <div styleName={'weight'}>
                      <span styleName={'keyword'}>{labels.priceLabel} </span>{props.price > 0 ? <PriceTag price={props.price} /> : <i>{labels.preisAufNachfrageLabel}</i>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}}
        />)}}
  </Location>
)

export default Bogen;


const bogenLabelsQuery = graphql `
  query bogenLabelsQuery {
    allContentfulProductLabels {
      nodes {
        priceLabel
        weightLabel
        preisAufNachfrageLabel
      }
    }
  }
`
